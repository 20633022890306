<template>
  <div class="manage-project-sales-vp">
    <div class="container fluid">
      <vacant-possession-detail
        :getVPDetailAPI="vpAPI.getVPDetail"
        :completeVPAPI="vpAPI.completeVP"
        listingRouteName="ManageProjectVacantPossession"
      ></vacant-possession-detail>
    </div>
  </div>
</template>

<script>
import VacantPossessionDetail from "@/components/GlobalComponents/Shared/ProjectSales/VacantPossession/VacantPossessionDetail";
import { projectVacantPossession as vpAPI } from "@/api";

export default {
  components: {
    VacantPossessionDetail
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      vpAPI
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
</style>
